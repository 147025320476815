// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cv-js": () => import("./../../../src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-en-challenges-js": () => import("./../../../src/pages/en/challenges.js" /* webpackChunkName: "component---src-pages-en-challenges-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-portfolio-js": () => import("./../../../src/pages/en/portfolio.js" /* webpackChunkName: "component---src-pages-en-portfolio-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portafolio-js": () => import("./../../../src/pages/portafolio.js" /* webpackChunkName: "component---src-pages-portafolio-js" */),
  "component---src-pages-retos-js": () => import("./../../../src/pages/retos.js" /* webpackChunkName: "component---src-pages-retos-js" */)
}

